// @flow
import React from 'react'
import cx from 'classnames'
import {Note} from '../layout'

import * as styles from './Notes.module.css'

export type NotesProps = {
  title?: string,
  id?: string,
  noteItems: Array<{
    name: string,
    note: string,
    isNoBorder?: Boolean,
  }>,
}

export default function Notes(props: NotesProps) {
  const {noteItems, title, id} = props
  return (
    <div>
      <section
        className={cx(styles.notes_container, {
          [styles.no_border]: noteItems[0].isNoBorder,
        })}
      >
        <h4 className={styles.h4} id={id || 'notes'}>
          {title || 'Notes'}
        </h4>
        {noteItems.map((note, i) => {
          return <Note key={i} {...note} />
        })}
      </section>
    </div>
  )
}
