// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'
import * as styles from './MainNav.module.css'

import type {Submenu} from './types'

export type Props = {|
  ...Submenu,
  className?: string,
  onToggle?: (name: string | null) => void,
  gtm: {
    action: string,
    category: string,
    label: string,
  },
|}

export default function NavLink(props: Props) {
  const {
    gtm: {category, label, action},
    className,
  } = props
  const linkout = props.linkout || false
  return (
    <>
      {linkout && (
        <a
          href={props.url}
          className={cx(className)}
          target="_blank"
          rel="noopener noreferrer"
          data-gtm-category={category}
          data-gtm-label={label}
          data-gtm-action={action}
        >
          {props.name}
          {props.description && (
            <div className={styles.nav_sub_description}>
              {props.description}
            </div>
          )}
        </a>
      )}
      {!linkout && (
        <Link
          to={props.url}
          className={cx(className)}
          data-gtm-category={category}
          data-gtm-label={label}
          data-gtm-action={action}
        >
          {props.name}
          {props.description && (
            <div className={styles.nav_sub_description}>
              {props.description}
            </div>
          )}
        </Link>
      )}
    </>
  )
}

export function NavButton(props: Props) {
  return (
    <Link
      to={props.url}
      className={styles}
      data-gtm-category={props.gtm.category}
      data-gtm-label={props.gtm.label}
      data-gtm-action={props.gtm.action}
    >
      {props.name} ›
    </Link>
  )
}
