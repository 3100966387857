// @flow
import * as React from 'react'

export type LinkOutProps = {
  /** Link Text as Children */
  children?: React.Node,
  /** url for href */
  url: string,
  /** GTM properties for tracking */
  gtm: {|action: string, category: string, label: string|},
  /** Optional className */
  className?: string,
}

export default function LinkOut(props: LinkOutProps) {
  const {
    url,
    className,
    gtm: {category, label, action},
  } = props
  return (
    <a
      href={url}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      data-gtm-category={category}
      data-gtm-label={label}
      data-gtm-action={action}
    >
      {props.children}
    </a>
  )
}
