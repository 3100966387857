// @flow

import * as React from 'react'
import {ContentHalf, ResourceNote} from '../layout'

import * as styles from './Resources.module.css'

import {type IconName} from '../icons/icon-data'

export type ResourcesProps = {
  ResourceData: Array<{
    url: string,
    noteType: string,
    name: string,
    iconName: IconName,
    alt?: string,
  }>,
}

export default function Resources(props: ResourcesProps) {
  const {ResourceData} = props
  return (
    <div>
      <section>
        <h4 className={styles.h4} id="resources">
          Resources
        </h4>
        <div className={styles.resources_content}>
          {ResourceData.map((resource, i) => {
            return (
              <ContentHalf key={i}>
                <ResourceNote {...resource} />
              </ContentHalf>
            )
          })}
        </div>
      </section>
    </div>
  )
}
