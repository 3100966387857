import React from 'react'

import {Icon} from '../../../ui-components'
import {ICON_DATA} from './data'

import logo from './images/opentrons_logo_white.svg'

import * as styles from './styles.module.css'

export const LogoAndSocial = () => (
  <div className={styles.container}>
    <img className={styles.logo} src={logo} alt="" />
    <div className={styles.icons}>
      {ICON_DATA.map((icon, i) => {
        return (
          <a href={icon.url} key={i} target="_blank" rel="noopener noreferrer">
            <Icon iconName={icon.name} />
          </a>
        )
      })}
    </div>
  </div>
)
