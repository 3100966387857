// @flow

import * as React from 'react'
import cx from 'classnames'
import {Link} from 'gatsby'
import Menu from './Menu'

import * as styles from './StickyMenu.module.css'

export type StickyMenuPcrProps = {
  menuData: {
    links: Array<string>,
    url: string,
    workflow: string,
  },
  className?: string,
  scrollSpyItems?: Array<string>,
  resetMenu?: boolean,
  gtmCategory?: string,
  protocolGtmLabel?: string,
  demoGtmLabel?: string,
}

export default function StickyMenuPcr(props: StickyMenuPcrProps) {
  const {
    menuData,
    className,
    scrollSpyItems,
    resetMenu,
    gtmCategory,
    protocolGtmLabel,
    demoGtmLabel,
  } = props

  const protocolButtonProps = {
    children: 'DOWNLOAD FLYER',
    to: 'https://insights.opentrons.com/hubfs/Products/Workstations/PCR%20Workstation%20One%20Sheeter.pdf',
    Component: Link,
    gtmAction: 'click',
    gtmCategory: gtmCategory,
    gtmLabel: protocolGtmLabel,
    white: true,
  }

  const demoButtonProps = {
    children: 'SCHEDULE A DEMO',
    Component: 'a',
    linkOutProps: {
      url: 'https://insights.opentrons.com/schedule-demo',
      gtm: {
        action: 'click',
        category: gtmCategory || 'b-header',
        label: demoGtmLabel || 'schedule-demo',
      },
    },
    white: true,
  }

  return (
    <div
      className={cx(styles.desktop_menu, {
        [styles.reset_top_menu]: resetMenu,
      })}
    >
      <section className={styles.fixed_scroll}>
        <Menu
          {...menuData}
          className={className}
          protocolButtonProps={protocolButtonProps}
          demoButtonProps={demoButtonProps}
          scrollSpyItems={scrollSpyItems}
        />
      </section>
    </div>
  )
}
