// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './BenefitList.module.css'

export type BenefitListProps = {
  boldText?: string,
  text?: string,
  className?: string,
  childClassName?: string,
}

export default function BenefitList(props: BenefitListProps) {
  const {className, childClassName, text, boldText} = props
  return (
    <>
      <ul className={cx(styles.bullet_point, className)}>
        <li className={childClassName}>
          <span className={styles.bold}>{boldText} </span>
          {text}
        </li>
      </ul>
    </>
  )
}
