// @flow
import * as React from 'react'
import cx from 'classnames'
import HubspotForm from 'react-hubspot-form'

import * as styles from './Hubspot.module.css'

export type HubspotEmailFormProps = {
  portalId: string,
  formId: string,
  className?: string,
  buttonClassName?: string,
}

export default function HubspotEmailForm(props: HubspotEmailFormProps) {
  const {className, buttonClassName, portalId, formId} = props
  return (
    <>
      <HubspotForm
        portalId={portalId}
        formId={formId}
        onSubmit={() => console.log('Submit!')}
        onReady={form => console.log('Form ready!')}
        loading={<div>Loading...</div>}
        cssClass={cx(styles.hubspot_form, className)}
        submitButtonClass={cx(styles.hubspot_button, buttonClassName)}
      />
    </>
  )
}
