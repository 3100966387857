// @flow
import cx from 'classnames'
import * as React from 'react'
import * as styles from './Modal.module.css'

export type OverlayProps = {
  /** optional onClick handler */
  onClick?: (event: React.SyntheticEvent<>) => mixed,
  alertOverlay?: ?boolean,
}

export default function Overlay(props: OverlayProps) {
  const {onClick} = props

  const overlayStyles = cx(styles.overlay, {
    [styles.clickable]: onClick,
  })

  return <div className={overlayStyles} onClick={onClick} />
}
