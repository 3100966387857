// @flow
import * as React from 'react'
import cx from 'classnames'
import * as styles from './LabeledValue.module.css'

type Props = {
  /** Label */
  label: React.Node,
  /** Value */
  value: React.Node,
  /** Additional className */
  className?: string,
  /** Additional value className */
  valueClassName?: string,
}

export default function LabeledValue(props: Props) {
  const {label, value} = props
  const className = cx(styles.labeled_value, props.className)

  return (
    <div className={className}>
      <span className={styles.labeled_value_label}>{label}: </span>
      <span className={cx(props.valueClassName)}>{value}</span>
    </div>
  )
}
