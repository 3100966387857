// @flow
import React from 'react'
import cx from 'classnames'

import * as styles from './TitledMessage.module.css'

export type TitledMessageProps = {
  title?: string,
  msg?: string,
  strikeoutText?: string,
  containerClassName?: string,
  paragraphClassName?: string,
}

export default function TitledMessage(props: TitledMessageProps) {
  return (
    <div className={cx(props.containerClassName)}>
      <h6 className={styles.h6}>
        {props.strikeoutText && (
          <span className={styles.strikeout}>{props.strikeoutText} </span>
        )}
        <span>{props.title}</span>
      </h6>
      <p className={cx(styles.p, props.paragraphClassName)}>{props.msg}</p>
    </div>
  )
}
