// @flow
import * as React from 'react'
import Logo from './Logo'
import NavList from './NavList'
import * as styles from './MainNav.module.css'

export default function MainNav() {
  return (
    <div className={styles.main_nav_contents}>
      <Logo />
      <NavList />
    </div>
  )
}
