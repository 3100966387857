// @flow
import * as React from 'react'

import * as styles from './WorkFlow.module.css'

export type WorkflowProps = {
  imgSrc: string,
  stepNumber?: string,
  name: string,
  content: string,
}

export default function WorkFlow(props: WorkflowProps) {
  const {imgSrc, stepNumber, name, content} = props
  return (
    <>
      <div>
        <div className={styles.workflow}>
          <div className={styles.workflow_img}>
            <img src={imgSrc} alt="" />
          </div>
          <div className={styles.workflow_content}>
            <span>Step {stepNumber}</span>
            <p className={styles.h5}>{name}</p>
            <p className={styles.p}>{content}</p>
          </div>
        </div>
      </div>
    </>
  )
}
