// @flow
import * as React from 'react'

import iconSrc, {type IconName} from './icon-data'

export type IconProps = {
  /** override class to apply instead of default */
  className?: string,
  /** Parent class to apply */
  parentClassName?: string,
  /** Required icon name */
  iconName: IconName,
  /** icon contents */
  children?: React.Node,
  /** alternative if img doesn't render */
  alt?: string,
  /** custom width */
  width?: string,
  /** custom height */
  height?: string,
  /** custom styles for parent div */
  parentClassName?: string,
  /** no parent div */
  noParentDiv?: boolean,
  onClick?: Function,
}

export default function Icon(props: IconProps) {
  const {iconName, alt, width, height, parentClassName, noParentDiv, onClick} =
    props
  const widthStyle = {
    width: width || 'auto',
    height: height || 'auto',
  }
  const IconStyles = props.className
  const src = iconSrc[iconName]
  if (noParentDiv) {
    return <img className={IconStyles} src={src} alt={alt} style={widthStyle} />
  }

  return (
    <div className={parentClassName} onClick={onClick}>
      <img className={IconStyles} src={src} alt={alt} style={widthStyle} />
    </div>
  )
}
