// @flow
import * as React from 'react'
import {Copyright} from './Copyright'
import * as styles from './Footer.module.css'
import {Links} from './Links'
import {LogoAndSocial} from './LogoAndSocial'
import {Newsletter} from './Newsletter'
import {Warranty} from './Warranty'

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div>
        <div className={styles.footer_layout}>
          <div className={styles.logo_and_social_container}>
            <LogoAndSocial />
          </div>
          <div className={styles.newsletter_container}>
            <Newsletter />
          </div>
          <div className={styles.warranty_container}>
            <Warranty />
          </div>
          <div className={styles.links_container}>
            <Links />
          </div>
          <div className={styles.copyright_container}>
            <Copyright />
          </div>
        </div>
      </div>
    </div>
  )
}
