// @flow
import React from 'react'
import cx from 'classnames'

import * as styles from './Note.module.css'

export type NoteProps = {
  note: string,
  name: string,
  isNoBorder?: Boolean,
}

export default function Note(props: NoteProps) {
  const {note, name, isNoBorder} = props
  const [isOpen, setIsOpen] = React.useState(!name)
  return (
    <>
      <div
        className={cx(styles.note_container, {
          [styles.no_border]: isNoBorder || !name,
        })}
      >
        {name && (
          <div
            className={styles.note_content}
            onClick={() => setIsOpen(!isOpen)}
          >
            <p className={styles.p}>{name}</p>
            <span className={isOpen ? styles.arrow_up : styles.arrow_down} />
          </div>
        )}
        <p className={isOpen ? null : styles.note}>{note}</p>
      </div>
    </>
  )
}
