// @flow
import * as React from 'react'
import cx from 'classnames'

import * as styles from './ApplicationBadge.module.css'

import BADGE from './assets/group-3.svg'

export type ApplicationBadgeProps = {
  textSrc: string,
  className?: string,
}

export default function ApplicationBadge(props: ApplicationBadgeProps) {
  const {textSrc, className} = props
  return (
    <div className={cx(styles.bg_color, className)}>
      <img src={BADGE} alt="" />
      <h6 className={styles.h6}>{textSrc}</h6>
    </div>
  )
}
