// @flow

import * as React from 'react'

import {ContentHalf, RelatedContent} from '../layout'

import {Button} from '../../ui-components'

import * as styles from './Related.module.css'

import type {ButtonProps} from '../buttons/Button'

export type RelatedProps = {
  buttons?: Array<ButtonProps>,
  relatedContent: Array<{
    readInfo: string,
    title: string,
    content: string,
    url: string,
  }>,
}

export default function Related(props: RelatedProps) {
  const {relatedContent, buttons} = props
  return (
    <div className={styles.related_bg_color}>
      <section className={styles.related_container}>
        <div className={styles.related}>
          <div className={styles.related_application}>
            <h4 className={styles.h4}>Related Applications</h4>
            <div className={styles.button_container}>
              {buttons &&
                buttons.map((button, i) => {
                  return (
                    <Button
                      // TODO (mh 2020-03-25): className get overridden by button component (will fix later)
                      key={i}
                      className={styles.related_button}
                      {...button}
                    />
                  )
                })}
            </div>
            <h4 className={styles.related_content}>Related Content</h4>
            <div className={styles.related_content_card}>
              {relatedContent.map((relatedContent, i) => {
                return (
                  <ContentHalf key={i}>
                    <RelatedContent {...relatedContent} />
                  </ContentHalf>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
