// @flow
import * as React from 'react'
import cx from 'classnames'
import * as styles from './Button.module.css'

import type {LinkOutProps} from '../interaction-enhancers/LinkOut'

export type ButtonProps = {
  /**  click handler */
  onClick?: (event: React.SyntheticMouseEvent<>) => mixed,
  /** button contents */
  children?: React.Node,
  /** override class to apply instead of default */
  className?: string,
  /** Google Tag Manager category param */
  gtmCategory?: string,
  /** Google Tag Manager label param */
  gtmLabel?: string,
  /** Google Tag Manager action param defaults to 'click' */
  gtmAction?: string,
  /** disable the button and remove onClick */
  disabled?: boolean,
  /** request button outline */
  outline?: boolean,
  /**  Navigation button */
  nav?: boolean,
  /** application button */
  application?: boolean,
  /** White button */
  white?: boolean,
  /** Orange button */
  orange?: boolean,
  /** custom element/component to use instead of default <button> */
  Component?: React.ElementType,
  /** When button as gatsby-link, the route to link to */
  to?: string,
  /** When button as LinkOut, apply these props as well */
  linkOutProps?: LinkOutProps,
  state?: Object,
}

export default function Button(props: ButtonProps) {
  const {outline, nav, application, white, to, linkOutProps, orange, state} =
    props

  const buttonStyles = cx(props.className, styles.button, {
    [styles.outline]: outline,
    [styles.nav]: nav,
    [styles.app_button]: application,
    [styles.white]: white,
    [styles.orange]: orange,
  })
  const gtmAction = props.gtmAction || 'click'
  const Component = props.Component || 'button'
  const hasGTM = props.gtmAction && props.gtmLabel && props.gtmCategory

  if (linkOutProps && linkOutProps.url && linkOutProps.gtm) {
    const url = linkOutProps.url
    const gtm = linkOutProps.gtm
    return (
      <Component
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
        className={buttonStyles}
        data-gtm-category={gtm.category}
        data-gtm-label={gtm.label}
        data-gtm-action={gtm.action}
      >
        {props.children}
      </Component>
    )
  }
  if (hasGTM) {
    return (
      <Component
        onClick={props.onClick}
        className={buttonStyles}
        data-gtm-category={props.gtmCategory}
        data-gtm-label={props.gtmLabel}
        data-gtm-action={gtmAction}
        to={to}
        state={state}
      >
        {props.children}
      </Component>
    )
  }
  return (
    <Component
      onClick={props.onClick}
      className={buttonStyles}
      to={to}
      state={state}
    >
      {props.children}
    </Component>
  )
}
