// @flow

import * as React from 'react'
import cx from 'classnames'

import {Icon, AppLink} from '../../ui-components'

import * as styles from './Card.module.css'

import type {IconName} from '../../ui-components'

export type QuoteCardProps = {
  quote: string,
  iconName: IconName,
  name: string,
  schoolName: string,
  blogUrl?: string,
}

export default function QuoteCard(props: QuoteCardProps) {
  const {quote, iconName, name, schoolName, blogUrl} = props

  return (
    <div
      className={cx(styles.quote_data_container, {
        [styles.large_height]: blogUrl,
      })}
    >
      <Icon className={styles.quote_icon} iconName={iconName} />
      <p className={styles.quote_p}>{`"${quote}"`}</p>
      <span className={styles.quote_name}>{name}</span>
      <span className={styles.quote_school_name}>{schoolName}</span>
      {blogUrl && <AppLink url={blogUrl}>Read blog ›</AppLink>}
    </div>
  )
}
