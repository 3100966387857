// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './BulletPoint.module.css'
import {AppLink} from '.'

export type Link = {
  title: string,
  url?: string,
  bulletItems?: (string | Link)[],
}

export type BulletPointProps = {
  names: (string | Link)[],
  className?: string,
  childClassName?: string,
  noColumns?: boolean,
}

export default function BulletPoint(props: BulletPointProps) {
  const {names, className, childClassName, noColumns} = props
  return (
    <>
      <ul
        className={cx(
          styles.bullet_point,
          className,
          noColumns ? styles.no_columns : ''
        )}
      >
        {names.map((name, i) => {
          return (
            <li className={childClassName} key={i}>
              {typeof name !== 'string' ? (
                <>
                  {name.url ? (
                    <AppLink url={name.url}>{name.title}</AppLink>
                  ) : (
                    <>{name.title}</>
                  )}
                  {name.bulletItems && name.bulletItems.length > 0 && (
                    <ul>
                      {(name.bulletItems || []).map((name, i) => {
                        return (
                          <li className={childClassName} key={i}>
                            {typeof name !== 'string' ? (
                              <AppLink url={name.url}>{name.title}</AppLink>
                            ) : (
                              name
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </>
              ) : (
                name
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}
