// analytics utilities

let clientId = null

export function initialize() {
  if (typeof window.ga !== 'function') {
    return console.warn('No Google Analytics instance found')
  }

  window.ga('require', 'outboundLinkTracker')
  window.ga('require', 'urlChangeTracker')
}

export function getClientId() {
  if (!clientId) {
    clientId = loadClientId()
  }

  return clientId
}

function loadClientId() {
  try {
    const tracker = window.ga.getAll()[0]
    return tracker.get('clientId')
  } catch (error) {
    console.warn('Error loading GA tracker')
    console.warn(error)
  }

  return null
}
