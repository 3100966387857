// @flow
import * as React from 'react'
import {Icon} from '../icons'

import * as styles from './ProtocolsTable.module.css'

import type {ProtocolData} from './protocols-data'

type Props = {|
  /** Array of feature names and if they apply to the designer or api */
  data: Array<ProtocolData>,
  /** Optional className */
  className?: string,
|}

export default function ProtocolsTableAlt(props: Props) {
  const {data} = props
  return (
    <table className={styles.protocol_table_white}>
      <thead>
        <tr>
          <th className={styles.feature}>features</th>
          <th className={styles.pd}>protocol designer</th>
          <th className={styles.api}>python api</th>
        </tr>
      </thead>
      <tbody>
        {data.map((d, i) => (
          <tr key={i}>
            <th>{d.feature}</th>
            <td className={styles.td_pd}>
              {d.designer ? <Icon iconName="check-green" /> : ''}
            </td>
            <td>{d.api ? <Icon iconName="check-green" /> : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
