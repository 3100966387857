// @flow
import React from 'react'

import * as styles from './ResourceNote.module.css'

import {Icon} from '../icons'
import {type IconName} from '../icons/icon-data'

export type ResourceNoteProps = {
  url: string,
  noteType: string,
  name: string,
  iconName: IconName,
  alt?: string,
}

export default function ResourceNote(props: ResourceNoteProps) {
  const {noteType, iconName, url, alt, name} = props

  return (
    <>
      <a
        href={url}
        className={styles.resource_note_container}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.icon_image}>
          <Icon iconName={iconName} alt={alt} />
        </div>
        <div>
          <div className={styles.resource_note}>
            <h6 className={styles.h6}>{noteType}</h6>
            <p className={styles.p}>{name}</p>
          </div>
        </div>
      </a>
    </>
  )
}
