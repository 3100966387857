// @flow

import * as React from 'react'
import Scrollspy from 'react-scrollspy'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import cx from 'classnames'

import {Button} from '../buttons'
import type {ButtonProps} from '../buttons'

import * as styles from './Menu.module.css'

export type MenuProps = {
  protocolButtonProps: ButtonProps,
  demoButtonProps: ButtonProps,
  links: Array<string>,
  linkScrollIds?: {[link: string]: string},
  url: string,
  workflow: string,
  className?: string,
  scrollSpyItems?: Array<string>,
}

export default function Menu(props: MenuProps) {
  const {
    links,
    linkScrollIds,
    workflow,
    protocolButtonProps,
    demoButtonProps,
    className,
    scrollSpyItems,
  } = props
  return (
    <>
      <div className={styles.menu}>
        <Scrollspy
          className={styles.list}
          items={scrollSpyItems}
          currentClassName={styles.active_state}
        >
          {links.map((link, i) => {
            const linkId =
              (linkScrollIds || {})[link] ||
              link.replace(/ /g, '_').toLowerCase()
            return (
              <li className={styles.list_items} key={i}>
                <AnchorLink href={`#${linkId}`} offset="110">
                  {link}
                </AnchorLink>
              </li>
            )
          })}
        </Scrollspy>
      </div>
      <div className={cx(styles.callback, className)}>
        <p>{workflow}</p>
        <Button className={cx(styles.button)} {...protocolButtonProps} />
        <Button className={styles.button} {...demoButtonProps} />
      </div>
    </>
  )
}
