// @flow
import * as React from 'react'

import * as styles from './WorkflowAlt.module.css'
import type {WorkflowProps} from '..'

export default function WorkflowAlt(props: WorkflowProps) {
  const {imgSrc, name, content} = props
  return (
    <>
      <div>
        <div className={styles.workflow}>
          <div className={styles.workflow_img}>
            <img src={imgSrc} alt="" />
          </div>
          <div className={styles.workflow_content}>
            <p className={styles.h5}>{name}</p>
            <p className={styles.p}>{content}</p>
          </div>
        </div>
      </div>
    </>
  )
}
