// @flow
import * as React from 'react'
import cx from 'classnames'
import {RemoveScroll} from 'react-remove-scroll'

import Overlay from './Overlay'
import IconButton from '../buttons/IconButton'
import * as styles from './Modal.module.css'

export type ModalProps = {
  /** Modal Contents */
  children: React.Node,
  /** optional class to apply */
  className?: string,
  /** classes to apply to the contents box */
  contentsClassName?: string,
  /** classes to apply to the header div */
  headerClassName?: string,
  /** optional handler for overlay click */
  onCloseClick?: (event: React.SyntheticEvent<>) => mixed,
  /** restricts scroll outside of Modal when open, true by default */
  restrictOuterScroll?: boolean,
  /** optional modal heading */
  header?: string,
}

export default function Modal(props: ModalProps) {
  const {onCloseClick, restrictOuterScroll = true} = props
  const Wrapper = restrictOuterScroll ? RemoveScroll : React.Fragment
  return (
    <Wrapper>
      <div className={cx(styles.modal, props.className)}>
        <Overlay onClick={onCloseClick} />
        <div className={styles.modal_div}>
          <div className={cx(styles.header, props.headerClassName)}>
            {props.header}
          </div>
          <IconButton
            iconName="close"
            onClick={onCloseClick}
            className={styles.close}
          />
          <div className={props.contentsClassName}>{props.children}</div>
        </div>
      </div>
    </Wrapper>
  )
}
