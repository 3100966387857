// @flow
import * as React from 'react'
import * as styles from './SpecTable.module.css'

import type {PipetteSpec} from './pipette-data'

export type PipetteSpecProps = {specData: Array<PipetteSpec>}

export default function SpecTable(props: PipetteSpecProps) {
  const {specData} = props
  return (
    <table className={styles.spec_table}>
      <caption>Specifications</caption>
      <thead>
        <tr className={styles.th_labels}>
          <th scope="col">Available Models</th>
          <th scope="col">Volume</th>
          <th scope="col" colSpan="2">
            Accuracy
          </th>
          <th scope="col" colSpan="2">
            Precision
          </th>
        </tr>
        <tr className={styles.th_units}>
          <th scope="col" colSpan="2">
            &nbsp;
          </th>
          <th scope="col">%D</th>
          <th scope="col">µl</th>
          <th scope="col">%CV</th>
          <th scope="col">µl</th>
        </tr>
      </thead>
      {specData.map((pipette, i) => {
        return (
          <tbody key={i}>
            {pipette.data.map((data, index) => {
              const {
                volume,
                randomErrorP,
                randomErrorU,
                systematicErrorP,
                systematicErrorU,
              } = data
              if (index === 0) {
                return (
                  <tr key={index}>
                    <th scope="row" rowSpan="3" className={styles.th_pipette}>
                      {pipette.model.split('\n').map((text, key) => {
                        return <div key={key}>{text}</div>
                      })}
                    </th>
                    <td>{volume}</td>
                    <td>{randomErrorP}</td>
                    <td>{randomErrorU}</td>
                    <td>{systematicErrorP}</td>
                    <td>{systematicErrorU}</td>
                  </tr>
                )
              } else {
                return (
                  <tr key={index}>
                    <td>{volume}</td>
                    <td>{randomErrorP}</td>
                    <td>{randomErrorU}</td>
                    <td>{systematicErrorP}</td>
                    <td>{systematicErrorU}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        )
      })}
    </table>
  )
}
