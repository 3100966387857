export const ICON_DATA = [
  {name: 'facebook', url: 'https://www.facebook.com/opentronslabworks/'},
  {
    name: 'instagram',
    url: 'https://www.instagram.com/opentrons_/?utm_source=ig_embed&hl=en',
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/opentrons?lang=en',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCvMRmXIxnHs3AutkVhuqaQg',
  },
]
