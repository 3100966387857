// @flow
import * as React from 'react'

import * as styles from './RelatedContent.module.css'

export type RelatedContentProps = {
  readInfo: string,
  title: string,
  content: string,
  url: string,
}

export default function RelatedContent(props: RelatedContentProps) {
  const {readInfo, title, content, url} = props

  return (
    <>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.related_content}>
          <h6 className={styles.h6}>{readInfo}</h6>
          <p className={styles.p}>{title}</p>
          <p className={styles.content}>{content}</p>
        </div>
      </a>
    </>
  )
}
