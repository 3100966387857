// @flow
import * as React from 'react'

import * as styles from './Workflow.module.css'
import type {WorkflowProps} from '../layout/WorkFlow'
import WorkflowAlt from '../layout/WorkflowAlt'

export type WorkflowSectionProps = {
  title?: string,
  description?: string,
  data: WorkflowProps[],
}

export default function Workflow(props: WorkflowSectionProps) {
  const {title, data, description} = props
  return (
    <div className={styles.workflow_container}>
      <div className={styles.header}>
        <h6>Workstations</h6>
        <h4>{title} Workflow</h4>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.steps}>
        {data.map(workflowProps => (
          <WorkflowAlt key={workflowProps.name} {...workflowProps} />
        ))}
      </div>
    </div>
  )
}
