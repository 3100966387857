// @flow
import * as React from 'react'
import {RemoveScroll} from 'react-remove-scroll'
import Overlay from './Overlay'
import IconButton from '../buttons/IconButton'

import * as styles from './Modal.module.css'

export type LightBoxProps = {
  /** handler to close the lightbox video */
  onCloseClick?: (event: React.SyntheticEvent<>) => mixed,
  /** link src of vimeo video */
  videoSrc?: string,
  /** link src of image */
  imgSrc?: string,
  /** Change Default width (640px)  */
  width?: string,
  /** Change Default height (360px)  */
  height?: string,
  /** restricts scroll outside of Modal when open, true by default */
  restrictOuterScroll?: boolean,
}

export default function LightBox(props: LightBoxProps) {
  const {videoSrc, onCloseClick, restrictOuterScroll = true, imgSrc} = props
  const Wrapper = restrictOuterScroll ? RemoveScroll : React.Fragment
  return (
    <Wrapper>
      <div className={styles.modal} onClick={onCloseClick}>
        <Overlay onClick={onCloseClick} />
        <div className={styles.iframe_container}>
          {videoSrc ? (
            <iframe
              className={styles.iframe}
              src={`${videoSrc}?autoplay=1&title=0&byline=0&portrait=0?autoplay=1&title=0&byline=0&portrait=0`}
              width="720"
              height="480"
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
              allow="autoplay"
            />
          ) : (
            <img src={imgSrc} className={styles.image} alt="" />
          )}
          <IconButton
            className={styles.lightbox_button}
            onClick={onCloseClick}
            iconName="video-close"
          />
        </div>
      </div>
    </Wrapper>
  )
}
