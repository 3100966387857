// @flow

import * as React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'

import * as styles from './AppLink.module.css'

export type AppLinkProps = {
  children: string,
  url: string,
  className?: string,
  trueLink?: Boolean,
  gtmCategory?: string,
  gtmLabel?: string,
}

export default function AppLink(props: AppLinkProps) {
  const {children, url, className, trueLink, gtmCategory, gtmLabel} = props
  if (trueLink) {
    return (
      <Link
        className={cx(styles.links, className)}
        to={url}
        data-gtm-category={gtmCategory}
        data-gtm-label={gtmLabel}
        data-gtm-action="click"
      >
        {children}
      </Link>
    )
  }
  return (
    <a
      className={cx(styles.links, className)}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      data-gtm-category={gtmCategory}
      data-gtm-label={gtmLabel}
      data-gtm-action="click"
    >
      {children}
    </a>
  )
}
