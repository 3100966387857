// @flow
import * as React from 'react'
import cx from 'classnames'
import HubspotForm from 'react-hubspot-form'

import * as styles from './Hubspot.module.css'

export type InlineHubspotButtonProps = {
  portalId: string,
  formId: string,
  className?: string,
  buttonClassName?: string,
}

export default function InlineHubspotButton(props: InlineHubspotButtonProps) {
  const {className, buttonClassName, portalId, formId} = props
  return (
    <div>
      <HubspotForm
        portalId={portalId}
        formId={formId}
        onReady={form => console.log('Form ready!')}
        loading={<div>Loading...</div>}
        cssClass={cx(styles.demo_hubspot_form, className)}
        submitButtonClass={cx(styles.demo_hubspot_button, buttonClassName)}
      />
    </div>
  )
}

export const addGtmTags = (category: string, action: string) => {
  setTimeout(() => {
    const buttonClass = '.Hubspot-module--demo_hubspot_button--2r41w'
    const element = document.querySelector(buttonClass)
    if (element) {
      element.setAttribute('data-gtm-category', category)
      element.setAttribute('data-gtm-label', action)
      element.setAttribute('data-gtm-action', 'click')
    }
  }, 1500)
}
