// @flow

import * as React from 'react'
import cx from 'classnames'
import {Material} from '../layout'
import BulletPoint from './BulletPoint'

import type {MaterialProps} from '../layout'
import type {Link} from '.'

import * as styles from './OpentronsMaterial.module.css'

export type OpentronsMaterialProps = {
  materialItems: Array<MaterialProps>,
  bulletItems?: Array<string | Link>,
  noBorder?: boolean,
  description: string,
  title?: String,
  id?: string,
}

export default function OpentronsMaterial(props: OpentronsMaterialProps) {
  const {materialItems, bulletItems, noBorder, description, title, id} = props
  return (
    <div
      className={cx(styles.opentrons_materials_container, {
        [styles.no_border]: noBorder,
      })}
    >
      <section>
        <h4 className={styles.h4} id={id || 'opentrons_materials'}>
          {title || 'Opentrons Materials'}
        </h4>
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.material_wrap_container}>
          {materialItems &&
            materialItems.map((material, i) => {
              return <Material key={i} {...material} />
            })}
        </div>
        {bulletItems && (
          <div>
            <p className={styles.p}>Additional Materials</p>
            <div>
              <BulletPoint names={bulletItems} />
            </div>
          </div>
        )}
      </section>
    </div>
  )
}
