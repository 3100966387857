// @flow

import * as React from 'react'

import {WorkFlow} from '../layout'

import type {WorkflowProps} from '../layout'

import * as styles from './Instructions.module.css'
import Workflow from './Workflow'

export type InstructionsProps = {
  instructions?: boolean,
  description?: string,
  title?: string,
  subtitle?: string,
  workflowItems: Array<WorkflowProps>,
  alt?: boolean,
}

export default function Instructions(props: InstructionsProps) {
  const {instructions, description, workflowItems, alt, title, subtitle} = props
  return (
    <div className={styles.workflow_container}>
      <section>
        <h4 className={styles.h4} id="workflow">
          Workflow
        </h4>
        {description && <p className={styles.p}>{description}</p>}
        {instructions && <h5 className={styles.h5}>Instructions</h5>}
        {workflowItems ? (
          alt ? (
            <Workflow
              data={workflowItems}
              title={title}
              description={subtitle}
            />
          ) : (
            workflowItems.map((workflow, i) => {
              return <WorkFlow key={i} {...workflow} />
            })
          )
        ) : null}
      </section>
    </div>
  )
}
