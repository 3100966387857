// @flow

import * as React from 'react'
import BulletPoint from './BulletPoint'

import * as styles from './Overview.module.css'
import type {Link} from '.'

export type OverviewProps = {
  content: React.Node,
  workflowCompatibility: string,
  listBulletpoint: Array<string | Link>,
}

export default function Overview(props: OverviewProps) {
  const {content, workflowCompatibility, listBulletpoint} = props
  return (
    <div className={styles.overview_container}>
      <section>
        <h4 className={styles.h4} id="overview">
          Overview
        </h4>
        {content}
        <div className={styles.workflow}>
          <p className={styles.p}>{workflowCompatibility}</p>
          <div>
            <BulletPoint names={listBulletpoint} />
          </div>
        </div>
      </section>
    </div>
  )
}
