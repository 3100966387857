// extracted by mini-css-extract-plugin
export var modal = "Modal-module--modal--I3Yuk";
export var modal_div = "Modal-module--modal_div--D9XMt";
export var header = "Modal-module--header--impFJ";
export var close = "Modal-module--close--SKOvh";
export var clickable = "Modal-module--clickable--THogY";
export var overlay = "Modal-module--overlay---XgKQ";
export var iframe_container = "Modal-module--iframe_container--TZlA2";
export var iframe = "Modal-module--iframe--gjD-Z";
export var lightbox_button = "Modal-module--lightbox_button--8Q5hQ";
export var image = "Modal-module--image--S0oP9";