// @flow

import * as React from 'react'

import * as styles from './VideoHero.module.css'

import VIDEO_ICON from './assets/video-icon.svg'

export type VideoHeroProps = {
  onClick: (event: React.SyntheticMouseEvent<>) => mixed,
  videoStill: string,
  title: string,
}

export default function VideoHero(props: VideoHeroProps) {
  const {onClick, videoStill, title} = props
  return (
    <div className={styles.video}>
      <div className={styles.video_container}>
        <article>
          <figure className={styles.figure} onClick={onClick}>
            <img src={videoStill} alt="" />
            <img src={VIDEO_ICON} alt="" className={styles.play_icon} />
            <div className={styles.video_details}>
              <span>{title}</span>
            </div>
          </figure>
        </article>
      </div>
    </div>
  )
}
