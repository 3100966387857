// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './HeadingGroup.module.css'

export type HeadingGroupProps = {
  header?: string,
  subHeader?: string,
  alignCenter?: boolean,
  /** optional class to apply */
  className?: string,
}

export default function HeadingGroup(props: HeadingGroupProps) {
  return (
    <div className={cx(props.className)}>
      <h3 className={styles.h3}>{props.header}</h3>
      <h5 className={styles.h5}>{props.subHeader}</h5>
    </div>
  )
}
