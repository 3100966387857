// @flow
import * as React from 'react'
import HubspotForm from 'react-hubspot-form'
import {Formik} from 'formik'

import Modal from '../modal/Modal'
import {HS_FORM_ID, HS_PORTAL_ID} from './contact-us-data'

import * as styles from './ContactUs.module.css'

export type ContactUsProps = {
  /** Handler for overlay click */
  onCloseClick: (event: React.SyntheticEvent<>) => mixed,
  /** Optional onSubmit override */
  onSubmit?: (event: React.SyntheticEvent<>) => mixed,
  portalId?: string,
  formId?: string,
}

export default function ContactUs(props: ContactUsProps) {
  const [success, setSuccess] = React.useState<boolean>(false)

  const formId = props.formId || HS_FORM_ID
  const portalId = props.portalId || HS_PORTAL_ID
  if (success) {
    return (
      <Modal
        header="Your message has been sent!"
        onCloseClick={props.onCloseClick}
        headerClassName={styles.success_header}
      >
        <p className={styles.success_message}>
          Thanks for your email! A member of our team within respond within 24
          hours.
        </p>
      </Modal>
    )
  }
  return (
    <Modal
      header="Email Our Team"
      onCloseClick={props.onCloseClick}
      className={styles.email_modal}
    >
      <Formik
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'This field is required'
          }
          if (!values.email) {
            errors.email = 'This field is required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          return errors
        }}
        onSubmit={(values, {setSubmitting}) => {
          setTimeout(() => {
            // TODO: Wire up after deciding on submit method
            console.log(JSON.stringify(values, null, 2))
            setSubmitting(false)
          }, 400)
          setSuccess(true)
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <HubspotForm
              portalId={portalId}
              formId={formId}
              onSubmit={() => console.log('Submit!')}
              onReady={form => console.log('Form ready!')}
              loading={<div>Loading...</div>}
              cssClass={styles.hubspot_form}
              submitButtonClass={styles.hubspot_button}
            />
          </form>
        )}
      />
    </Modal>
  )
}
