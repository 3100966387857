// @flow
import * as React from 'react'
import cx from 'classnames'

import * as styles from './SavingsBadge.module.css'

export type SavingsBadgeProps = {
  savings: Text,
  className?: string,
}

export default function SavingsBadge(props: SavingsBadgeProps) {
  const {savings, className} = props
  return (
    <div className={cx(styles.bg_color, className)}>
      <h6 className={styles.h6}>Save {savings}</h6>
    </div>
  )
}
