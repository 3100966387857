// @flow
import React from 'react'

import {Button} from '../buttons'

import * as styles from './ShopItem.module.css'

import type {ButtonProps} from '../buttons'

export type ShopItemProps = {
  itemName: string,
  itemPrice: string,
  itemDiscountPrice?: string,
  buttonProps: ButtonProps,
}

export default function ShopItem(props: ShopItemProps) {
  const {itemName, itemPrice, itemDiscountPrice, buttonProps} = props

  return (
    <div className={styles.shop_item_container}>
      <p className={styles.item_name}>{itemName}</p>
      {itemDiscountPrice ? (
        <div className={styles.item_price}>
          <span>{itemDiscountPrice}</span>
          <span>
            <s>{itemPrice}</s>
          </span>
        </div>
      ) : (
        <p className={styles.item_price}>{itemPrice}</p>
      )}

      <Button {...buttonProps} />
    </div>
  )
}
