// @flow

import * as React from 'react'
import cx from 'classnames'

import * as styles from './Hero.module.css'

export type HeroProps = {
  application: string,
  backgroundClassName?: string,
  /*  Must give text a left position */
  textClassName?: string,
  /*  Must give h2 a left position and bottom */
  h2ClassName?: string,
}

export default function Hero(props: HeroProps) {
  const {application, backgroundClassName, textClassName, h2ClassName} = props
  return (
    <>
      <section className={styles.hero}>
        <div className={cx(styles.hero_container, backgroundClassName)}>
          <div>
            <div className={cx(styles.validation, textClassName)}>
              <span>Applications</span>
            </div>
            <h2 className={cx(styles.hero_h2, h2ClassName)}>{application}</h2>
          </div>
        </div>
      </section>
    </>
  )
}
