// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'
import {ClickOutside, Button} from '../../../ui-components'

import NavData from './../../../../static/api/navigationData.json'
import NavMenu from './NavMenu'
import MenuButton from './MenuButton'
import * as styles from './MainNav.module.css'

type Props = {||}

export default function NavList(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [menu, setMenu] = React.useState(null)

  const clear = () => setMenu(null)

  const toggle = (name: string | null) => setMenu(menu !== name ? name : null)

  const toggleMobile = () => {
    if (isOpen === false) {
      document.body !== null && document.body.classList.add('no_scroll')
    } else {
      document.body !== null && document.body.classList.remove('no_scroll')
    }
    setIsOpen(!isOpen)
  }

  React.useEffect(() => {
    return () => {
      document.body !== null && document.body.classList.remove('no_scroll')
    }
  }, [])

  return (
    <>
      <MenuButton onMobileClick={toggleMobile} isMobileOpen={isOpen} />
      <ClickOutside onClickOutside={clear}>
        {({ref}) => (
          <ul
            className={cx(styles.nav_list, {
              [styles.nav_list_open]: isOpen,
              [styles.nav_list_submenu_open]: menu,
            })}
            ref={ref}
          >
            {NavData.navigationList.map(subnav => (
              <NavMenu
                {...subnav}
                key={subnav.name}
                active={menu === subnav.name}
                onToggle={name => toggle(name)}
              />
            ))}
            <Button
              className={styles.nav_contact_button}
              to="/contact"
              Component={Link}
              gtmLabel={'contact-us'}
              gtmCategory={'b-header'}
              gtmAction={'click'}
            >
              CONTACT SALES
            </Button>
            <Button
              className={styles.demo_button}
              Component="a"
              linkOutProps={{
                url: 'https://insights.opentrons.com/schedule-demo',
                gtm: {
                  action: 'click',
                  category: 'b-header',
                  label: 'schedule-demo',
                },
              }}
              outline
            >
              SCHEDULE DEMO
            </Button>
          </ul>
        )}
      </ClickOutside>
    </>
  )
}
