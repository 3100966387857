// @flow
import * as React from 'react'
import NavLink from './NavLink'
import NavMenuItems from './NavMenuItems'
import * as styles from './MainNav.module.css'

import type {Submenu} from './types'
import cx from 'classnames'

type Props = {|
  ...Submenu,
  name: string,
  className?: string,
  onToggle: (name: string | null) => void,
  /**
   * The offset (pixels) is added to shift drodown menu.
   * Please see the `dropdown_small` in the `MainNav.module.css`
   * for transform implementation details
   */
  offset?: {
    x?: string,
    y?: string,
  },
|}

export default function NavMenu({offset, ...props}: Props) {
  const {name, links, url, text, active, onToggle, centered} = props

  /**
   * The two flags below are set with a delay when `active` is true:
   * isActive is set with 1ms delay and isOpen with 100ms delay
   * This is done to ensure that:
   * - correct css variable `--navmenu-dropdown-offset-*` is set before
   * the dropdown is shown (relies on isActive)
   * - the `dropdown_small_open` class is added with a delay
   * so the sliding animation is enabled on mobile
   */

  const [isOpen, setIsOpen] = React.useState(false)
  const [isActive, setIsActive] = React.useState(false)

  const isOpenTimeout = React.useRef()
  const isActiveTimeout = React.useRef()

  React.useEffect(() => {
    clearTimeout(isOpenTimeout.current)
    clearTimeout(isActiveTimeout.current)

    if (active) {
      isActiveTimeout.current = setTimeout(() => setIsActive(active), 1)
      isOpenTimeout.current = setTimeout(() => setIsOpen(active), 100)
    } else {
      setIsOpen(false)
      setIsActive(false)
    }
  }, [active])

  /**
   * Updating the offset css variables values when the item is active.
   */
  React.useEffect(() => {
    if (active && document) {
      const root = document.documentElement
      if (root) {
        root.style.setProperty(
          '--navmenu-dropdown-offset-x',
          offset == null || offset.x === undefined ? `0px` : `${offset.x}`
        )
        root.style.setProperty(
          '--navmenu-dropdown-offset-y',
          offset == null || offset.y === undefined ? `0px` : `${offset.y}`
        )
      }
    }
  }, [active, offset])

  return (
    <li
      className={cx(styles.nav_url, {
        [styles.nav_url_active]: isActive,
        [styles.nav_url_parent]: !!links,
      })}
      role="button"
      onClick={() => onToggle(name)}
    >
      {url ? (
        <NavLink {...props} className={styles.nav_url_name} />
      ) : (
        <span className={styles.nav_url_name}>{name}</span>
      )}

      {isActive && links ? (
        <div
          className={cx(styles.dropdown_small, {
            [styles.dropdown_small_open]: isOpen,
            [styles.dropdown_small_centered]: centered,
          })}
        >
          <button
            onClick={() => onToggle(null)}
            className={styles.nav_sub_name}
          >
            {name}
          </button>
          <ul
            className={cx(styles.nav_sub, {
              [styles.nav_sub_support]: name.toLowerCase() === 'support',
            })}
          >
            {links.map((subnav, index) => (
              <NavMenuItems key={index} {...subnav} />
            ))}
          </ul>
          {text ? (
            <div
              dangerouslySetInnerHTML={{__html: text}}
              className={styles.nav_text}
            />
          ) : null}
        </div>
      ) : null}
    </li>
  )
}
