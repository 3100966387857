import {Link} from 'gatsby'
import React from 'react'
import {LinkOut} from '../../../ui-components'
import {footerLinksData} from './data'

import * as styles from './styles.module.css'

export const Links = () => (
  <>
    <div className={styles.groups}>
      {footerLinksData.map(group => (
        <ul key={group.name} className={styles.group}>
          <h4 className={styles.group_title}>{group.name}</h4>
          {group.links.map(link => {
            const linkContent = link.trueLink ? (
              <Link
                className={styles.link}
                to={link.url}
                data-gtm-category={link.gtm.category}
                data-gtm-label={link.gtm.label}
                data-gtm-action={link.gtm.action}
              >
                {link.name}
              </Link>
            ) : (
              <LinkOut className={styles.link} url={link.url} gtm={link.gtm}>
                {link.name}
              </LinkOut>
            )

            return <li key={link.name}>{linkContent}</li>
          })}
        </ul>
      ))}
    </div>
  </>
)
