// @flow
import * as React from 'react'

export type ClickOutsideProps = {
  onClickOutside: ?(MouseEvent) => mixed,
  children: ({ref: React.Ref<*>}) => React.Element<*>,
}

export default class ClickOutside extends React.Component<ClickOutsideProps> {
  wrapperRef: ?Element

  constructor(props: ClickOutsideProps) {
    super(props)
    this.wrapperRef = null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef = (el: ?Element) => {
    this.wrapperRef = el
  }

  handleClickOutside = (event: MouseEvent) => {
    const clickedElem = event.target

    if (!(clickedElem instanceof Node)) {
      console.warn(
        'expected clicked element to be Node - something went wrong in ClickOutside'
      )
      return
    }

    if (
      this.props.onClickOutside &&
      this.wrapperRef &&
      !this.wrapperRef.contains(clickedElem)
    ) {
      this.props.onClickOutside(event)
    }
  }

  render() {
    return this.props.children({ref: this.setWrapperRef})
  }
}
