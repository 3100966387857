// @flow
export type ProtocolData = {|
  feature: string,
  designer: boolean,
  api: boolean,
|}

export const PD_COMPARE_DATA = [
  {
    feature: 'Intuitive drag and drop graphical interface',
    designer: true,
    api: false,
  },
  {
    feature: 'Visual labware and liquid management',
    designer: true,
    api: false,
  },
  {
    feature: 'Ability to customize pipetting technique',
    designer: true,
    api: true,
  },
  {
    feature: 'Opentrons Standard Labware',
    designer: true,
    api: true,
  },
  {
    feature: 'Custom Labware',
    designer: true,
    api: true,
  },
  {
    feature: 'Opentrons Modules',
    designer: true,
    api: true,
  },
  {
    feature: 'CSV Import',
    designer: false,
    api: true,
  },
  {
    feature: 'User Defined Variables and Conditional Logic',
    designer: false,
    api: true,
  },
]
