// @flow
import * as React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import '../../styles/global.css'
import * as styles from './Page.module.css'
import {initialize as initializeAnalytics} from '../../analytics'

export type PageProps = {
  children?: React.Node,
}

export default function Page(props: PageProps) {
  React.useEffect(() => {
    initializeAnalytics()
  })
  return (
    <div className={styles.page_container}>
      <Nav />
      <div>{props.children}</div>
      <Footer />
    </div>
  )
}
