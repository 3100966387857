import React from 'react'

import HubspotForm from 'react-hubspot-form'
import {HS_FORM_ID, HS_PORTAL_ID} from './data'

import * as styles from './styles.module.css'

export const Newsletter = () => (
  <div>
    <div className={styles.info}>
      <h4 className={styles.title}>Sign up for our newsletter</h4>
      <p className={styles.description}>
        Get regular updates on our latest products and newest educational
        articles.
      </p>
    </div>
    <HubspotForm
      portalId={HS_PORTAL_ID}
      formId={HS_FORM_ID}
      loading={<div>Loading...</div>}
      cssClass={styles.hubspot_form}
      submitButtonClass={styles.hubspot_button}
    />
  </div>
)
