// @flow

import type {FooterLinksGroup} from './types'

export const footerLinksData: Array<FooterLinksGroup> = [
  {
    name: 'Products',
    links: [
      {
        name: 'OT-2',
        url: '/ot-2/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'ot-2'},
      },
      {
        name: 'OT-2 Pipettes',
        url: '/pipettes/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'pipettes'},
      },
      {
        name: 'Opentrons App',
        url: '/ot-app/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'app'},
      },
      {
        name: 'Protocol Library',
        url: 'https://protocols.opentrons.com/',
        gtm: {action: 'click', category: 'b-footer', label: 'protocol'},
      },
      {
        name: 'Shop all products',
        url: 'https://shop.opentrons.com/',
        gtm: {action: 'click', category: 'b-footer', label: 'shop'},
      },
    ],
  },
  {
    name: 'Company',
    links: [
      {
        name: 'About',
        url: '/about/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'about'},
      },
      {
        name: 'Team',
        url: '/team/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'team'},
      },
      {
        name: `We're Hiring`,
        url: '/jobs/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'jobs'},
      },
      {
        name: 'Contact Us',
        url: '/contact/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'contact-us'},
      },
      {
        name: `Pandemic Response Lab`,
        url: 'https://pandemicresponselab.com/',
        gtm: {action: 'click', category: 'b-footer', label: 'prl'},
      },
    ],
  },
  {
    name: 'Resources',
    links: [
      {
        name: `Support`,
        url: 'https://support.opentrons.com/',
        gtm: {action: 'click', category: 'b-footer', label: 'support'},
      },
      {
        name: `Opentrons API`,
        url: 'https://docs.opentrons.com/',
        gtm: {action: 'click', category: 'b-footer', label: 'api'},
      },
      {
        name: `Open Source`,
        url: 'https://github.com/Opentrons/',
        gtm: {action: 'click', category: 'b-footer', label: 'open-source'},
      },
      {
        name: `Guide to lab automation`,
        url: 'https://opentrons.com/publications/ultimate-guide-to-choosing-a-pipetting-robot-for-lab-automation.pdf',
        gtm: {action: 'click', category: 'b-footer', label: 'playbook'},
      },
      {
        name: `Download the app`,
        url: '/ot-app/',
        trueLink: true,
        gtm: {action: 'click', category: 'b-footer', label: 'ot-app'},
      },
      {
        name: 'Partnerships',
        url: 'https://insights.opentrons.com/partnership-program/',
        gtm: {action: 'click', category: 'b-footer', label: 'partnerships'},
      },
    ],
  },
]
