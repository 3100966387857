// @flow
// top nav bar logo image
import * as React from 'react'
import {Link} from 'gatsby'
import logoSrc from './images/ot-logo-full.png'

import * as styles from './MainNav.module.css'

export default function Logo() {
  return (
    <Link
      to="/"
      data-gtm-category="l-header"
      data-gtm-label="logo"
      data-gtm-action="click"
    >
      <img className={styles.logo} src={logoSrc} />
    </Link>
  )
}
