// @flow
import * as React from 'react'
import {IconButton} from '../../../ui-components'
import * as styles from './MainNav.module.css'

import type {MobileNavProps} from './types'

export default function MenuButton(props: MobileNavProps) {
  return (
    <IconButton
      title="menu"
      iconName={props.isMobileOpen ? 'x' : 'menu'}
      className={styles.nav_button}
      onClick={props.onMobileClick}
    />
  )
}
