// @flow
// icon data
const IconSrc = {
  arrows: require('../../images/icon/arrows.svg').default,
  'back-arrow': require('../../images/icon/arrow-icon.svg').default,
  'back-arrow-blue': require('../../images/icon/back-arrow-blue.svg').default,
  'bullet-point': require('../../images/icon/bullet-point.svg').default,
  check: require('../../images/icon/check.svg').default,
  'check-green': require('../../images/icon/check-green.svg').default,
  close: require('../../images/icon/close.svg').default,
  'down-arrow': require('../../images/icon/arrow-down.svg').default,
  'up-arrow': require('../../images/icon/arrow-up.svg').default,
  facebook: require('../../images/icon/facebook.svg').default,
  'facebook-gray': require('../../images/icon/facebook-gray.svg').default,
  info: require('../../images/icon/info-round-black.svg').default,
  instagram: require('../../images/icon/instagram.svg').default,
  'instagram-gray': require('../../images/icon/instagram-gray.svg').default,
  'list-closed': require('../../images/icon/list-closed.svg').default,
  menu: require('../../images/icon/nav-mobile.svg').default,
  'pdf-download': require('../../images/icon/pdf-download.svg').default,
  'pdf-download-light-gray':
    require('../../images/icon/pdf-icon_light-grey.svg').default,
  protocol: require('../../images/icon/protocol.svg').default,
  'protocol-blue': require('../../images/icon/protocol-blue.svg').default,
  'protocol-light-gray': require('../../images/icon/protocol-light-grey.svg')
    .default,
  'grey-protocol-icon': require('../../images/icon/grey-protocol-icon.svg')
    .default,
  twitter: require('../../images/icon/twitter.svg').default,
  'twitter-gray': require('../../images/icon/twitter-gray.svg').default,
  validated: require('../../images/icon/validated.svg').default,
  quote: require('../../images/icon/quote.svg').default,
  'video-button': require('../../images/icon/video-button.svg').default,
  'video-button-round': require('../../images/icon/video-button-round.svg')
    .default,
  'video-close': require('../../images/icon/video-close.svg').default,
  'video-sample': require('../../images/icon/video-sample.svg').default,
  wifi: require('../../images/icon/wifi.svg').default,
  youtube: require('../../images/icon/youtube.svg').default,
  'youtube-gray': require('../../images/icon/youtube-gray.svg').default,
  'chevron-left': require('../../images/icon/chevron-left.svg').default,
  'chevron-right': require('../../images/icon/chevron-right.svg').default,
  'carousel-left': require('../../images/icon/carousel-left.svg').default,
  'carousel-right': require('../../images/icon/carousel-right.svg').default,
  x: require('../../images/icon/x-icon.svg').default,
  error_warning: require('../../images/icon/error-warning.svg').default,
}

// eslint-disable-next-line no-undef
export type IconName = $Keys<typeof IconSrc>

export default IconSrc
