// @flow

import * as React from 'react'
import cx from 'classnames'
import * as styles from './Content.module.css'

export type ContentHalfProps = {
  children?: React.Node,
  alignCenter?: boolean,
  /** optional class to apply */
  className?: string,
}

export default function ContentHalf(props: ContentHalfProps) {
  return (
    <div className={cx(styles.content_half, props.className)}>
      {props.children}
    </div>
  )
}
