// extracted by mini-css-extract-plugin
export var main_nav_contents = "MainNav-module--main_nav_contents--DtS-D";
export var nav_button = "MainNav-module--nav_button--CQjof";
export var logo = "MainNav-module--logo--9pcL4";
export var nav_list = "MainNav-module--nav_list--tMfnu";
export var nav_list_open = "MainNav-module--nav_list_open--Ey8MZ";
export var nav_url = "MainNav-module--nav_url--hKKDr";
export var nav_url_active = "MainNav-module--nav_url_active--gimsa";
export var nav_list_submenu_open = "MainNav-module--nav_list_submenu_open--wbXJG";
export var nav_url_name = "MainNav-module--nav_url_name--uxbda";
export var nav_url_parent = "MainNav-module--nav_url_parent--XmDjH";
export var nav_text = "MainNav-module--nav_text--82NYf";
export var dropdown_small = "MainNav-module--dropdown_small--31iya";
export var dropdown_small_centered = "MainNav-module--dropdown_small_centered--Ke8O1";
export var dropdown_small_open = "MainNav-module--dropdown_small_open--zhQak";
export var nav_sub = "MainNav-module--nav_sub--Xw7iM";
export var nav_sub_name = "MainNav-module--nav_sub_name--y0uYv";
export var nav_sub_support = "MainNav-module--nav_sub_support--4pBVt";
export var nav_sub_item = "MainNav-module--nav_sub_item--7PGkA";
export var nav_sub_sub = "MainNav-module--nav_sub_sub--IP8lE";
export var nav_sub_link = "MainNav-module--nav_sub_link--hbyOI";
export var nav_sub_title = "MainNav-module--nav_sub_title--2jufA";
export var nav_sub_description = "MainNav-module--nav_sub_description--E5Gln";
export var nav_contact_button = "MainNav-module--nav_contact_button--Y-TE2";
export var demo_button = "MainNav-module--demo_button--7iouU";
export var nav_highlighted_sub_title = "MainNav-module--nav_highlighted_sub_title--wd6IY";