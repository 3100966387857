// @flow

import * as React from 'react'

import CHECKMARK from './assets/validated.svg'
import OMEGA from './assets/omega-bio-tek-logo.png'
import SWIFT from './assets/swift_logo.jpg'

import * as styles from './Validation.module.css'

export type ValidationProps = {
  content: string,
  badge?: Boolean,
  swift?: Boolean,
  omega?: Boolean,
  id?: string,
  noBorder?: Boolean,
  title?: string,
}

export default function Validation(props: ValidationProps) {
  const {content, badge, swift, omega, id, noBorder, title} = props
  return (
    <div className={`${styles.container} ${noBorder ? styles.no_border : ''}`}>
      <div>
        <div className={styles.header}>
          {badge && <img className={styles.image} src={CHECKMARK} alt="" />}
          <h4 className={styles.h4} id={id || 'verification'}>
            {title || 'Verification'}
          </h4>
        </div>
        <p className={styles.p}>{content}</p>
      </div>
      <div className={styles.logo_container}>
        {swift && <img className={styles.swift_image} src={SWIFT} alt="" />}
        {omega && <img className={styles.omega_image} src={OMEGA} alt="" />}
      </div>
    </div>
  )
}
