// @flow
import * as React from 'react'

import {Button} from '../../ui-components'

import * as styles from './Card.module.css'

import type {ButtonProps} from '../../ui-components'

export type FeatureCardProps = {
  cardName: string,
  cardDescription?: string,
  button: ButtonProps,
}

export default function FeatureCard(props: FeatureCardProps) {
  const {cardName, cardDescription, button} = props

  return (
    <div className={styles.feature_card_container}>
      <h5 className={styles.h5}>{cardName}</h5>
      <p className={styles.p}>{cardDescription}</p>
      <Button {...button} />
    </div>
  )
}
