// @flow

import * as React from 'react'
import cx from 'classnames'
import * as styles from './Form.module.css'

export type InputFieldProps = {
  /** input type */
  type: 'text' | 'email' | 'number',
  /** field is disabled if value is true */
  disabled?: boolean,
  /** change handler */
  onChange?: (event: React.SyntheticInputEvent<*>) => mixed,
  /** blur handler */
  onBlur?: (event: React.SyntheticFocusEvent<*>) => mixed,
  /** classes to apply to outer element */
  className?: string,
  /** name of field in form */
  name?: string,
  /** optional ID of <input> element */
  id?: string,
  /** placeholder text */
  placeholder?: string,
  /** current value of text in box, defaults to '' */
  value?: ?string,
  /** if included, InputField will use error style and display error instead of caption */
  error?: ?string,
  /** inline label text. */
  label?: string,
  /** classes to apply to inner label text div */
  labelTextClassName?: ?string,
  /** validation + * in label */
  required?: boolean,
  /** 100% width */
  fullWidth?: boolean,
  /** min and max values for input validation */
  max?: number,
  min?: number,
  /** prepend the currency symbol infornt of the input value, if used */
  currency?: string,
}

export default function InputField(props: InputFieldProps) {
  const {label, labelTextClassName} = props

  if (!label) {
    return (
      <div>
        <Input {...props} />
      </div>
    )
  }
  return (
    <label>
      <div className={cx(labelTextClassName)}>{props.label}</div>
      <Input {...props} />
    </label>
  )
}

function Input(props: InputFieldProps) {
  const {
    onChange,
    name,
    id,
    value,
    type,
    fullWidth,
    required,
    className,
    onBlur,
    error,
    min,
    max,
    currency,
  } = props
  const placeholder = props.placeholder || ''
  const inputStyles = cx(styles.input, className, {
    [styles.error]: error,
  })
  return (
    <div className={styles.input_field_container}>
      <div
        className={
          currency
            ? `${styles.currency_wrap} ${styles.input_field}`
            : styles.input_field
        }
      >
        {currency && <span className={styles.currency_code}>{currency}</span>}
        <input
          className={inputStyles}
          id={id}
          type={type || 'text'}
          value={value || ''}
          name={name}
          min={min}
          max={max}
          placeholder={required ? `*${placeholder}` : placeholder}
          onChange={onChange}
          onBlur={onBlur}
          style={{width: fullWidth}}
          required={required || false}
        />
        {props.error && <span className={styles.error_caption}>{error}</span>}
      </div>
    </div>
  )
}
