// @flow

import * as React from 'react'
import cx from 'classnames'
import BenefitList from './BenefitList'

import * as styles from './Benefit.module.css'

export type BenefitProps = {
  benefitContent: string,
  benefitList?: Array<string>,
  imageSrc?: string,
  imageItalicText?: string,
}

export default function Benefit(props: BenefitProps) {
  const {benefitContent, benefitList, imageSrc, imageItalicText} = props
  return (
    <div className={styles.benefit_container}>
      <section>
        <h4 className={styles.h4} id="benefits">
          Benefits
        </h4>
        <p className={styles.p}>{benefitContent}</p>
        {benefitList &&
          benefitList.map((list, i) => {
            return <BenefitList key={i} boldText={list} />
          })}

        {imageSrc && (
          <div>
            <img className={styles.image} src={imageSrc} alt="" />
            <p className={cx(styles.p, styles.italic)}>{imageItalicText}</p>
          </div>
        )}
      </section>
    </div>
  )
}
