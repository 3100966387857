// @flow
import React from 'react'

import * as styles from './Material.module.css'

export type MaterialProps = {
  imgSrc: string,
  name: string,
  price: string,
  url: string,
}

export default function Material(props: MaterialProps) {
  const {imgSrc, name, price, url} = props
  return (
    <>
      <a
        className={styles.material}
        rel="noopener noreferrer"
        target="_blank"
        href={url}
      >
        {imgSrc && (
          <div className={styles.material_img}>
            <img src={imgSrc} alt="" />
          </div>
        )}
        <div
          className={`${styles.material_text} ${imgSrc ? '' : styles.centered}`}
        >
          <p className={styles.p}>{name}</p>
          <span className={styles.price}>{price}</span>
          {price && (
            <div className={styles.view_product}>
              <span>VIEW PRODUCT ›</span>
            </div>
          )}
        </div>
      </a>
    </>
  )
}
