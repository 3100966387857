// @flow
import * as React from 'react'
import cx from 'classnames'

import Button from './Button'
import Icon from '../icons/Icon'

import * as styles from './Button.module.css'

import type {ButtonProps} from './Button'
import type {IconProps} from '../icons/Icon'

export type IconButtonProps = ButtonProps & IconProps

export default function IconButton(props: IconButtonProps) {
  return (
    <Button {...props} className={cx(props.className, styles.button_icon)}>
      <Icon
        iconName={props.iconName}
        className={styles.icon}
        width={props.width}
        height={props.height}
      />
      {props.children && (
        <span className={styles.icon_button_text}>{props.children}</span>
      )}
    </Button>
  )
}
