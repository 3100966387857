import React from 'react'

import * as styles from './styles.module.css'

export const Warranty = () => (
  <a
    className={styles.link}
    href="https://opentrons-landing-img.s3.amazonaws.com/Opentrons+Warranty.pdf"
    target="_blank"
    rel="noopener noreferrer"
  >
    Warranty, Terms & Conditions
  </a>
)
