// @flow
import * as React from 'react'
import NavLink from './NavLink'
import * as styles from './MainNav.module.css'
import type {Submenu} from './types'

type Props = {|
  ...Submenu,
  className?: string,
  onToggle?: (name: string | null) => void,
|}

export default function NavMenuItems(props: Props) {
  const {name, links, url, highlightedName} = props
  return (
    <li className={styles.nav_sub_item}>
      {url ? (
        <NavLink {...props} className={styles.nav_sub_link} />
      ) : name ? (
        <span className={styles.nav_sub_title}>
          {highlightedName && (
            <span className={styles.nav_highlighted_sub_title}>
              {highlightedName}
            </span>
          )}
          {name}
        </span>
      ) : null}

      {links ? (
        <ul className={styles.nav_sub_sub}>
          {links.map((subnav, index) => (
            <NavMenuItems {...subnav} key={index} />
          ))}
        </ul>
      ) : null}
    </li>
  )
}
