// @flow
// top subdomain nav bar component
import * as React from 'react'
import cx from 'classnames'
import {Link} from 'gatsby'
import {LinkOut} from '../../../ui-components'
import NavData from './../../../../static/api/navigationData.json'

import * as styles from './Subdomain.module.css'

export type Links = Array<{|
  name: string,
  url: string,
  gtm: {|action: string, category: string, label: string|},
  linkout: boolean,
|}>

export default function SubdomainNav() {
  return (
    <ul className={styles.subdomain_nav_contents}>
      <div />
      <div className={styles.link_contents}>
        {NavData.subdomainList.map((link, index) => {
          const {gtm, linkout, highlighted, url} = link
          const {category, label, action} = gtm
          const className = highlighted
            ? cx(styles.subdomain_link, styles.subdomain_link_highlighted)
            : styles.subdomain_link

          if (!linkout) {
            return (
              <li key={index}>
                <Link
                  to={url}
                  className={className}
                  data-gtm-category={category}
                  data-gtm-label={label}
                  data-gtm-action={action}
                >
                  {link.name}
                </Link>
              </li>
            )
          }
          return (
            <li key={index}>
              <LinkOut url={link.url} className={className} gtm={gtm}>
                {link.name}
              </LinkOut>
            </li>
          )
        })}
      </div>
    </ul>
  )
}
